<template>
   <div>
      <head>
         <meta charset="utf-8">
         <meta name="author" content="Schaffen IT">
         <link href="https://fonts.googleapis.com/css?family=Quicksand" rel="stylesheet">
         <link href="https://fonts.googleapis.com/css?family=Armata" rel="stylesheet">
         <link rel="stylesheet" type="text/css" href="style.css">
      </head>

      <a name="inicio"></a>
      <body>
         <header>
            <nav>
               <a href="https://www.schaffen-it.com/" target="_blank">
                  <img src="../assets/logo3rbg.png" alt="Foto do logo">
               </a>

               <ul>
                  <li><a @click="scrollToAnchor('inicio')">INÍCIO</a></li>
                  <li><a @click="scrollToAnchor('servicos')">PLANOS</a></li>
                  <li><a @click="scrollToAnchor('sobre')">SOBRE</a></li>
                  <li><a @click="scrollToAnchor('contato')">CONTATO</a></li>
               </ul>
               <a class="btn" href="https://wa.me/5542991612211?text=Olá,%20Gostaria%20de%20saber%20mais%20sobre%20o%20Agrofácil" target="_blank">
                  <button class="btnCadastrarHeader">
                     <span>Entre em contato!</span>
                  </button>
               </a>
            </nav>
         </header>
      </body>
   </div>
</template>

<script>
export default {
   methods: {
      scrollToAnchor(name) {
         // get the height of the fixed header
         const header = document.querySelector('header');
         const headerHeight = header.offsetHeight;

         console.log(headerHeight)

         // calculate the target position of the anchor
         const targetElement = document.getElementById(name);

         console.log(targetElement)

         if (!targetElement) {
               return; // target element doesn't exist, exit the function
         }
         const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;

         console.log(targetElement)

         // scroll to the target position
         window.scrollTo({
               top: targetPosition,
               behavior: 'smooth'
         });
      }
   }
};
</script>

<style scoped>

header {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   background-color: rgb(255, 255, 255);
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
   position: fixed;
   top: 0;
   z-index: 99; 
}


header img {
   width: 250px;
   margin: auto;
   margin-top: 0.6em;
}

header a {
   width: 250px;
   margin: auto;
   margin-top: 0.6em;
}

header .btn{
   margin: auto
}

nav {
   display: flex;
   justify-content: center;
   width: 100%;
   max-width: 90em;
}

nav ul {
   float: right;
   justify-content: center;
   width: auto;
   white-space: nowrap;
}

nav ul li {
   font-family: 'Quicksand', sans-serif;
   display: inline-block;
   font-size: 1.1em;
   margin-right: 2.5em;
   margin-top: 1.3em;
}

nav ul li a {
   text-decoration: none;
   color: #000000;
   font-weight: bold;
   text-shadow: 2px 2px 3px #bbbbbb;
}

nav ul li a:hover {
   transition: 1s;
   color: #047d1f;
   text-shadow: 0 0 4px #ffffff;
}

@media only screen and (max-width: 700px) {
   header .btn{
      display: none;
   }

   nav {
      display: block;
   }

   nav ul{
      width: 100%;
   }

   nav ul li {
      margin-right: 1.2em;
      margin-top: 0.0em;
      margin-bottom: 0.5em;
   }
}
</style>
<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import Home from './views/Home';

export default {
  name: 'App',

  components: {
    Home,
  },
};
</script>

<style>

@font-face {
  font-family: "hamburadul-italic";
  src: url(~@/assets/fonts/HambuRadul-Italic.ttf);
}

@font-face {
  font-family: "hamburadul-regular";
  src: url(~@/assets/fonts/HambuRadul-Regular.ttf);
}

@font-face {
  font-family: "ConcertOne-Regular";
  src: url(~@/assets/fonts/ConcertOne-Regular.ttf);
}

</style>

<template>
  <div>
    <HeaderMenu/>
    <HomePage/>
    <Servicos/>
    <Sobre/>
    <HomeFooter/>
  </div>
</template>

<script>
import HeaderMenu from '../components/HeaderMenu.vue'
import HomePage from '../components/HomePage.vue'
import HomeFooter from '../components/HomeFooter.vue'
import Servicos from '../components/Servicos.vue'
import Sobre from '../components/Sobre.vue'

export default {
  name: 'App',
  components: {
    HeaderMenu,
    HomePage,
    Servicos,
    Sobre,
    HomeFooter
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = 'AgroFácil - Schaffen IT';
      }
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

*{
    margin: 0;
    padding: 0;
}

.bg1{
  background-image: linear-gradient(#D9F1D7,#B1FFA8);
}
.bg2{
  background-image: linear-gradient(#B1FFA8,#D9F1D7);
}

.bodyType{
  margin: auto;
  max-width: 90em;
}

.border{
   border: 8px;
   border-style: solid;
   border-radius: 0em 10em 10em 0em;
   border-color: #ffffff;
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnTamanho {
  width: 300px;
  height: 80px;
  position: absolute;
}

.btnCadastrar {
  width: 100%;
  height: 6em;
  border-radius: 1em;
  color: #ffffff;
  background-color: #3EDC30;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btnCadastrarHeader {
  width: 12em;
  height: 2.5em;
  border-radius: 0.5em;
  color: #ffffff;
  background-color: #3EDC30;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 3px #000;
}

.btnCadastrar:hover {
  transition: 0.5s ease-in-out;
  background: #30b824;
}

.btnCadastrar span {
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 3px #000;
  font-size: 27px;
  font-weight: bold;
}

body {
  overflow-x: hidden;
}

@media only screen and (max-width: 700px) {
  .titulo{
    font-size: 1em;
  }
}
</style>
